import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)
export const store = new Vuex.Store({
  strict: true,
  state,
  mutations: {
    changeLan (state, currentLan) {
      state.currentLan = currentLan
    },
    changeConnect (state, isConnectAccount) {
      state.isConnectAccount = isConnectAccount
      console.log('state.isConnectAccount', state.isConnectAccount)
    },
    changeAccount (state, userAccountAddress) {
      state.userAccountAddress = userAccountAddress
      console.log('state.userAccountAddress', state.userAccountAddress)
    },
    changeChainID (state, currenChain) {
      state.currenChain = currenChain
    },
    setUserInfo(state, token) {
        state.userInfo = token;
      },
      clearUserInfo(state) {
        state.userInfo = {};
      },
    setToken(state, token) {
      state.token = token;
    },
    clearToken(state) {
      state.token = null;
    },
    setPlatform(state, token) {
      state.platform = token;
    },
    clearPlatform(state) {
      state.platform = "";
    },
  },
  actions: {
    changeLan (content, currentLan) {
      content.commit('changeLan', currentLan)
    },
    changeChainID (content, currenChain) {
      content.commit('changeChainID', currenChain)
    },
    saveUserInfo({ commit }, userInfo) {
        commit('setUserInfo', userInfo);
      },
      removeUserInfo({ commit }) {
        commit('clearUserInfo');
      },
    saveToken({ commit }, token) {
      commit('setToken', token);
    },
    removeToken({ commit }) {
      commit('clearToken');
    },
    savePlatform({ commit }, platform) {
      commit('setPlatform', platform);
    },
    removePlatform({ commit }) {
      commit('clearPlatform');
    }
  },
  getters: {
    getUserInfo(state) {
        return state.userInfo;
      },
    getToken(state) {
      return state.token;
    },
    getPlatform(state) {
      return state.platform;
    }
  },
  plugins: [
    createPersistedState({
      key: 'augame', 
      paths: ['token', 'userInfo','platform'], 
      storage: window.localStorage, 
    })
  ],
})
export default store;
