
let state = {

    web3: {
      isInjected: false,
      web3Instance: null,
      networkId: null,
      coinbase: null,
      balance: null,
      error: null
    },
    chainId: [713715],
    chainType: ['SEI'],
    contractInstance: null,
   
    currentLan: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
    currenChain: 713715,
    isConnectAccount: false || localStorage.getItem('wallet'),
    userAccountAddress: window.defaultAccount || '',
    token: '',
    platform: "",
    userInfo: {},
  }
  if (process.env.CHAINID_NO === 'TEST') {
    state.chainId = [713715]
  } else if (process.env.CHAINID_NO === 'PROD') {
    state.chainId = [713715]
  }
  export default state
  