// utils/customMessage.js
import Vue from 'vue';
import Tip from '@/components/tip.vue'; 
import { throttle } from './throttle';

const TipConstructor = Vue.extend(Tip);

const showTip = throttle((msg, type = 'success', duration = 2000) => {
    const instance = new TipConstructor({
        data: {
            msg: msg,
            type: type,
            duration: duration,
        },
    });
    instance.$mount();
    document.body.appendChild(instance.$el);

    instance.show(msg, type, duration);
}, 1500); 

export default showTip;