<template>
    <div>
        <transition name="van-slide-down">
            <div class="tip" v-if="visible">
                <img src="@/assets/img/success.svg" alt="" v-if="type == 'success'">
                <img src="@/assets/img/error.svg" alt="" v-if="type == 'error'">
                <img src="@/assets/img/notice.svg" alt="" v-if="type == 'warning'">
                <div class="ml10 fs14">{{ msg }}</div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'Tip',

    data() {
        return {
            visible: false,
            msg: 'ces',
            type: 'success',
            duration: 2000,
        }
    },
    methods: {
        show(msg, type = 'success', duration = 2000) {
            this.msg = msg;
            this.type = type;
            this.visible = true;
            setTimeout(() => {
                this.visible = false;
            }, duration);
        },
    }
};
</script>

<style scoped>
.tip {
    position: fixed;
    z-index: 9999;
    top: 17px;
    left:17px;
    width: 91.28vw;
    padding: 5px 10px;
    border: 6px solid rgba(0, 0, 0, 0.1);
    border-image: url("@/assets/img/tips-bg.png");
    border-image-slice: 6 6 6 6 fill;
    border-image-repeat: none;
    display: flex;
    align-items: center;
    color: #9EB2B5;
}
</style>