import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const Home = () => import("../views/home.vue");
const pocketquest = () => import("../views/pocketquest.vue");
const pocketquestwallet = () => import("../views/pocketquest-wallet.vue");




const routes = [
  {
    path: "/",
    component:Home,
  },
  {
    path: "/home",
    name: "home",
    component:Home,
  },
  {
    path: "/airdrop",
    name: "pocketquest",
    component:pocketquest,
  },
  {
    path: "/pocketquestwallet",
    name: "pocketquestwallet",
    component:pocketquestwallet,
  },
 
];

const router = new Router({
  mode: "history",
  routes,
  base:process.env.NODE_ENV === 'production' ? "./" : '/',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

export default router;
