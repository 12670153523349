<template>
    <van-overlay :show="loading" z-index="9999999">
      <div class="loading">
        <div class="center">
          <img src="@/assets/img/loading.gif" alt="">
        <div class="fc9EB2B5 mt16 fs12">{{text}}</div>
        </div>
      </div>
    </van-overlay>
  </template>
  
  <script>
  export default {
    name: 'Loading',
    props: {
      loading: {
        type: Boolean,
        required: true
      },
      text: {
        type: String,
        default: ''
      },
    }
  };
  </script>
  
  <style scoped>
    .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
.loading img {
  width: 30px;
  height: 30px;
}
  </style>
  